:root {
  --ghost-white: #f2f4ff;
  --sea-green: #519872;
  --amber-green: #77b66e;
  --jet-black: #39393a;
  --min-warm-main: #eae7dc;
  --min-warm-secondary: #d8c3a5;
  --min-warm-accent: #e98074;
  --min-warm-big-text: #e85a4f;
  --min-warm-text: #8e8d8a;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--min-warm-main);
  margin-bottom: calc(60px + 1rem);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/******************************
********** NAVBAR *************
*******************************/

.auth-button {
  padding: 12px 22px;
  background-color: #1976d2;
  color: #fff;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.auth-button:hover {
  background-color: #1565c0;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

/******************************
********** FORMS **************
*******************************/

.form-container {
  width: 60vw;
  max-width: 80vw;
  align-items: center;
  margin: 0 auto;
  padding-top: 40px;
}

.form-container h2 {
  color: var(--min-warm-big-text);
  margin: 0;
  font-weight: 900;
  font-size: 40px;
}

.form {
  padding: 14px;
}

.form-container > h2 {
  font-size: 30px;
  font-weight: 900;
}

.form-group {
  margin: 0.2rem 0 0.5rem 0;
  text-align: left; /* Left-align labels */
}

.form-group label {
  display: block;
  font-weight: bold;
}

.form-link:visited {
  font-weight: 700;
  color: #007bff;
  text-decoration: none;
}

.button-container {
  display: flex;
}

.sign-in-button {
  font-size: 18px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.sign-in-button:hover {
  background-color: #0056b3;
}

.post-review {
  width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center; /* Center the entire form */
}

.post-review h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center; /* Center the heading */
}

.review-photo {
  max-width: 100%; /* Adjust the maximum width of the photo */
  max-height: 200px; /* Adjust the maximum height of the photo */
  margin: 10px auto; /* Add some margin for spacing */
  display: block; /* Make the photo display as a block for centering */
}

/******************************
********** PROFILE ************
*******************************/

.user-profile {
  margin: auto;
  max-width: 200px !important;
}

.profile-picture {
  border-radius: 50%;
  object-fit: cover;
}

.user-name {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-top: 8px;
  margin-bottom: 0;
}

.user-handle {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 16px;
  color: #888;
}

.user-bio {
  font-size: 16px;
  color: #555;
  line-height: 1.5;
  margin: 0;
}

.btn-section {
  display: flex;
  gap: 10px;
}

.btn-section button {
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nav-bar {
  margin: auto;
  justify-content: center;
  border-top: 2px solid lightgrey;
}

.nav-bar button:hover {
  background-color: var(--min-warm-main);
}

.nav-bar button {
  color: var(--min-warm-text);
}

.nav-bar button.active {
  color: black;
}

/******************************
********* RES. SLIDER *********
*******************************/

.restaurant-slider {
  display: block;
  margin-top: 20px;
  align-items: center;
  width: 80vw;
  margin: auto;
}

.restaurant-slider .slider-container {
  background-color: var(--min-warm-secondary);
  margin: 20px 0 20px;
}

.restaurant-slider .slider-name {
  color: var(--min-warm-big-text);
  font-style: italic;
  font-size: 28px;
  font-weight: 900;
}

.restaurant-slider .reviews-container {
  display: flex;
  align-items: center;
}
