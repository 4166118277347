.faq-section {
  max-width: 600px;
  margin: auto;
  padding: 20px;
}

.faq-item {
  margin-bottom: 20px;
}

.question {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
}

.answer {
  padding: 10px;
}
