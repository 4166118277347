.review {
  border: solid var(--jet-black) 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--min-warm-main);
  border-radius: 10px;
  padding: 16px;
  color: var(--min-warm-text);
  width: 80vw;
  max-width: 360px;
}

.top-row {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 8px;
}

.username-link {
  text-decoration: none;
  color: var(--min-warm-text);
  font-weight: bold;
  display: flex;
  align-items: center;
}

.user-img {
  height: 30px;
  width: 30px;
  margin-right: 5px;
  border-radius: 50px;
}

.username {
  text-align: left;
}

.rating-box {
  display: flex;
  align-items: center;
}

.star {
  color: yellow;
}

.review .media-container {
  width: 100%;
}

.review .photo {
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 360px;
  margin-bottom: 8px;
  object-fit: cover;
}

.review .separator {
  width: 100%;
  height: 2px;
  background-color: #ddd;
  margin-bottom: 8px;
}

.review .restaurant-info {
  text-align: left;
  float: left;
  font-weight: bold;
}

.review .item-name {
  float: left;
  font-weight: 250;
  font-size: 14px;
}

.review .tags-box {
  width: inherit;
  max-width: 360px;
}

.review .review-text {
  text-align: left;
  margin-bottom: 8px;
  float: left;
}
