/* Navbar.css */

/* Container styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--min-warm-big-text);
  padding: 20px 40px;
  border-bottom: solid var(--min-warm-accent) 0.01px;
}

/* Logo styles */
.navbar h1 {
  margin: 0;
  font-size: 32px;
  font-weight: 900;
}

/* Adjust button position to the right */
.navbar .auth-button.sign-in {
  margin-left: auto;
}
