.search-container {
  text-align: center;
  margin: auto;
  margin-top: 20px;
  justify-content: center;
  width: 200px;
}

.search-bar {
  width: 200px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.search-results {
  top: 100%;
  left: 0;
  width: 220px;
  height: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
}

.search-results a {
  text-decoration: none;
}

.search-results div {
  padding: 8px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  color: black;
}

.search-results div:hover {
  background-color: #f5f5f5;
}
