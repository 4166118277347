/* restaurantModal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above other elements */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  height: 80vh;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  position: relative;
  text-align: center; /* Center text content */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: black; /* Add a color to make it visible on the overlay */

  transition: transform 0.2s ease-in-out; /* Add a smooth transition */
}

.close-button:hover {
  transform: scale(1.2); /* Scale up on hover */
  background-color: white;
}

.modal-reviews {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
