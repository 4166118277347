.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  height: 100%;
  background-color: var(--min-warm-main);
}

h1 {
  margin-bottom: 0;
  margin-top: 0;
}

.slogan-text {
  font-size: 18px;
  margin-top: 0;
  text-decoration: wavy;
}

.info-container {
  width: 50%;
  margin-bottom: 30px;
  font-style: italic;
}

.row {
  display: flex;
}

.row-left {
  width: 50%;
  margin: auto;
  height: 200px;
}

.row-right {
  background-color: var(--min-warm-accent);
  width: 50%;
  margin: auto;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.row-right-info {
  margin: 20px;
  text-align: center;
}

.row-left-info {
  margin: 20px;
  font-size: 40px;
  font-weight: 600;
  font-style: italic;
}

.icon {
  margin-bottom: -50px;
  font-size: 100px;
}

/* Media Query for Small Screens */
@media (max-width: 768px) {
  .info-container {
    width: 80%; /* Adjust the width as needed for smaller screens */
  }

  .row {
    flex-direction: column; /* Stack rows on top of each other */
  }

  .row-right {
    order: 1; /* Flip the order of the second row */
  }

  .row-left,
  .row-right {
    width: 100%;
  }
}
