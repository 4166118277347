/* RestaurantCard.css */

.restaurant-card {
  width: 100px; /* Adjust the size as needed */
  height: 100px; /* Adjust the size as needed */
  border-radius: 10px; /* Adjust the border-radius for rounded edges */
  border: 1px solid #333; /* Dark gray border color */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s; /* Add transition for the transform, box-shadow, and background-color properties */
  cursor: pointer; /* Change cursor to pointer on hover */
}

.restaurant-card p {
  margin: 0;
}

.number-badge {
  position: absolute;
  width: 12px;
  top: 5px;
  right: 5px;
  background-color: #d3d3d3; /* Light gray badge background color */
  color: #000; /* Black badge text color */
  padding: 5px;
  border-radius: 50%; /* Make it a circle */
  font-size: 12px;
}

.restaurant-card:hover {
  transform: translate(-2px, -2px) scale(1.1); /* Move to the left and up, then scale up */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add a subtle box shadow on hover */
  background-color: #f9f9f9; /* Light gray background color on hover */
}
