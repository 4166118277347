nav {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  max-width: 300px;
  height: 60px;
  background-color: var(--min-warm-secondary);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 15px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

nav ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100%; /* Ensure full width within the nav */
}

nav li {
  margin: 0;
}

nav a {
  text-decoration: none;
  color: var(--min-warm-text);
  padding: 10px;
  transition: color 0.3s ease;
}

nav a:hover {
  color: #555;
}
