.user-reviews {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center reviews horizontally */
  padding: 20px;
  margin: 0 auto; /* Center reviews container horizontally */
  max-width: 600px; /* Adjust the maximum width as needed */
}

.user-reviews .review {
  /* Add any styles for your individual reviews here */
  /* For example: */
  margin-bottom: 20px;
}

.user-restaurants {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Adjust the spacing between cards */
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.restaurant-reviews {
  margin-top: 20px;
}
